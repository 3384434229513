import React from 'react';

import PressScreen from '../components/screens/PressScreen/PressScreen';
import { GlobalStyle } from '../components/shared/global';
import PageMetadata from "../components/page-metadata"
import SiteHeader from "../components/layout/SiteHeader"

export default () => (
  <>
    <PageMetadata title="Press" />
    <GlobalStyle />
    <SiteHeader />
    <main>
      <PressScreen />
    </main>
  </>
);